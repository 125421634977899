import { i18n } from '@lingui/core'
import { detect, fromStorage, fromUrl } from '@lingui/detect-locale'

export const locales = {
  en: 'English',
  es: 'Español',
  ca: 'Català',
}

export type Locale = keyof typeof locales
export const defaultLocale: Locale = 'es'

const DEFAULT_FALLBACK = () => defaultLocale

export async function dynamicActivateLocale() {
  const locale = detect(
    fromUrl('lang'),
    fromStorage('lang'),
    // @TODO: Sanitize the locale from Navigator
    // fromNavigator(),
    DEFAULT_FALLBACK,
  )

  // @TODO: Check if the locale exists before trying to load it
  if (!locale) {
    throw new Error('No locale found')
  }

  const { messages } = await import(`./locales/${locale}.po`)

  i18n.load(locale, messages)
  i18n.activate(locale)
}

import '@fontsource-variable/montserrat'
import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router'
import { registerSW } from 'virtual:pwa-register'

import App from './App'
import './index.css'

const intervalMS = 60 * 60 * 1000

registerSW({
  immediate: true,
  onRegisteredSW(swUrl, registration) {
    if (!registration) return

    setInterval(async () => {
      if (registration.installing || !navigator) return

      if ('connection' in navigator && !navigator.onLine) return

      const response = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          'cache': 'no-store',
          'cache-control': 'no-cache',
        },
      })

      if (response?.status === 200) await registration.update()
    }, intervalMS)
  },
})

if (import.meta.env.PROD) {
  const initSentry = async () => {
    const { init, reactRouterV7BrowserTracingIntegration, replayIntegration } =
      await import('@sentry/react')

    init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        reactRouterV7BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', /^https:\/\/app\.copets\.es/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  }

  initSentry()
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
